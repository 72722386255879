import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ArticleSlider from "../components/article-slider"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
//import FeaturedSponsorship from "../components/featured-sponsorship"
import PageAdvert from "../components/page-advert"
import CtaSlider from "../components/cta-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/slider-image-v1.jpg"
import { Iframe } from "../components/iframe"
import ReactLightbox from "../components/react-lightbox"

const IndexPage = () => {
  const {
    allStrapiArticle,
    //strapiGlobal,
    strapiHome,
    allStrapiGallerySection,
  } = useStaticQuery(graphql`
    query {
      allStrapiArticle(
        filter: {
          category: { name: { eq: "Story" } }
          featured: { eq: true }
          location: { eq: "home-page" }
        }
        sort: { order: DESC, fields: updatedAt }
      ) {
        nodes {
          ...StrapiArticles
        }
      }
      allStrapiGallerySection(filter: { location: { eq: "home-page" } }) {
        nodes {
          ...StrapiGallerySection
        }
      }
      #strapiGlobal {
      #  ...StrapiGlobal
      #}
      strapiHome {
        hero {
          ...StrapiHero
        }
        switcherContent {
          ...SwitcherSections
        }
        pageAd {
          ...PageAdverts
        }
        featuredSponsorship {
          ...SponsorshipSections
        }
        testimonialSection {
          ...TestimonialCards
        }

        callToAction {
          ...StrapiCallToAction
        }
        seo {
          ...StrapiSeo
        }
        video {
          headings {
            title
            description
          }
          src
          title
          width
          height
          bg
        }
      }
    }
  `)

  const {
    hero,
    seo,
    //featuredSponsorship,
    pageAd,
    testimonialSection,
    switcherContent,
    video,
    callToAction,
  } = strapiHome

  const structuredData = seo.structuredData

  //const sponsorshipSections = featuredSponsorship

  const callToActionSections = callToAction.call_to_action_sections
  //console.log("callToAction: ", callToAction)

  const articles = allStrapiArticle.nodes

  const asterix = pageAd.page_adverts[0]

  const simplifyThings = pageAd.page_adverts[1]

  const featuredEvent = pageAd.page_adverts[2]

  const testimonialCards = testimonialSection.testimonial_cards

  const switcherSection = switcherContent.switcher_sections

  const gallerySection = allStrapiGallerySection.nodes[0]

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <SwitcherButtonsContent
        title="What we do!"
        description="A non-profit organisation, advocating for the homeless, vulnerable street dogs of Sri Lanka, as they suffer from hunger, starvation, disease, and terrible abuse."
        switcherSections={switcherSection}
        //title={strapiGlobal.siteName}
        //description={strapiGlobal.siteDescription}
        bg="uk-background-default"
      />
      <PageAdvert advert={asterix} bg="uk-background-muted" />

      <PageAdvert advert={featuredEvent} bg="uk-background-default" />

      {/* <FeaturedSponsorship
        sponsorshipSections={sponsorshipSections}
        title="Featured Sponsorship"
        description="Checkout some of our success stories, see how your donations and sponsorships have helped these street dogs, and the work we do"
        bg="uk-background-default"
      /> */}

      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonialCards}
      />

      <PageAdvert advert={simplifyThings} bg="uk-background-default" />

      <ReactLightbox
        images={gallerySection.images}
        title={gallerySection.headings.title}
        description={gallerySection.headings.description}
        bg="uk-background-muted"
        //remainingImages={remainingImages}
      />

      <Iframe video={video} />

      <ArticleSlider
        articles={articles}
        title="Featured Articles"
        description="Checkout some of our articles from Save the Street Pooches"
        bg="uk-background-muted"
      />

      <CtaSlider
        title={callToAction.title}
        description={callToAction.description}
        callToActionSections={callToActionSections}
        bg={callToAction.bg}
        image={callToAction.image}
        paypalButton={callToAction.paypalButton}
      />
    </>
  )
}

export default IndexPage
