import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Tiles from "./tiles"
import Headings from "./headings"

const SwitcherButtonsContent = ({
  switcherSections,
  title,
  description,
  bg = "uk-background-muted",
}) => {
  //console.log("Switcher Content: ", switcherSections)

  // switcherSections.nodes.map((section, index) => {
  //   console.log("switcher section: ", section.image[0].localFile)
  // })

  // switcherSections.nodes.map((switcherContent, index) => {
  //   console.log("switcher content: ", switcherContent)
  // })

  return (
    <section className={`uk-section ${bg} uk-padding-remove`}>
      <div className="uk-container uk-margin-medium-bottom">
        <Headings title={title} description={description} bg={bg} />
        <div className="uk-section uk-section-small uk-padding-remove uk-padding-remove-bottom">
          <ul
            className="uk-subnav uk-flex uk-flex-center"
            uk-switcher="connect: .uk-switcher; animation: uk-animation-fade"
          >
            {switcherSections.map((section, index) => (
              <li
                key={`section__${index}`}
                className="uk-margin-small-bottom uk-padding-remove"
              >
                <Link
                  to={section.switcherButton.link}
                  className="uk-button uk-button-default uk-button-small active uk-margin-small-bottom uk-text-small uk-animation-fade uk-animation-slide-top"
                  toggle="button"
                  style={{
                    borderColor: "#617F7F",
                    color: "#617F7F",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  {/* <h5
                    className="uk-margin-remove"
                    style={{ textTransform: "none" }}
                  >
                    <span
                      className="uk-margin-small-left"
                      uk-icon="icon: arrow-right; ratio: 1"
                    >
                      {section.switcherButton.name}
                    </span>
                  </h5> */}
                  <span
                    className="uk-visible@s"
                    style={{
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {section.switcherButton.name}
                  </span>
                  <span
                    className="uk-hidden@s"
                    style={{ textTransform: "none" }}
                  >
                    {section.switcherButton.name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <ul className="uk-switcher uk-margin-small-top">
          {switcherSections.map((section, index) => {
            //console.log("section: ", section)

            const leftContent = section.switcherLinkContent[0]
            const rightContent = section.switcherLinkContent[1]

            //console.log("Name: ", section.switcherButton.name)

            return (
              <li key={`section__${index}`}>
                <div
                  className="uk-grid uk-child-width-1-2@l uk-flex-middle"
                  uk-grid="true"
                  uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
                >
                  <div>
                    {section?.image && (
                      <GatsbyImage
                        image={getImage(section.image[0].localFile)}
                        alt={section.image[0].alternativeText}
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                    )}
                  </div>
                  {section.switcherButton.name !== "Values" ? (
                    <div uk-scrollspy-class="uk-animation-slide-right-medium">
                      <div>
                        <h5
                          className="uk-text-bold"
                          // style={{
                          //   color: "#617F7F",
                          //   fontWeight: "500",
                          // }}
                        >
                          {section.smallTitle}
                        </h5>
                      </div>

                      {/* <div></div> */}

                      <div className="uk-margin-small-bottom">
                        <h4 className="uk-margin-remove-bottom uk-margin-remove-top">
                          <span className="orange-grad uk-text-bold">
                            {section.largeTitle}
                          </span>
                        </h4>
                      </div>

                      <div>
                        <p
                          style={{
                            color: "#617F7F",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          {section.description}
                        </p>
                      </div>

                      <div
                        className="uk-grid uk-child-width-1-2@s"
                        uk-grid="true"
                      >
                        <div>
                          <h4 className="uk-margin-remove-bottom">
                            <span className="orange-grad uk-text-bold">
                              {leftContent.title}
                            </span>
                          </h4>
                          <p
                            style={{
                              color: "#617F7F",
                              fontWeight: "400",
                            }}
                          >
                            {leftContent.description}
                          </p>
                          <Link
                            className="hover-underline-animation-dark green-grad"
                            to={leftContent.link}
                          >
                            <h5 className="uk-margin-remove">
                              {leftContent.name}
                            </h5>
                          </Link>
                        </div>
                        <div>
                          <h4 className="uk-margin-remove-bottom">
                            <span className="orange-grad uk-text-bold">
                              {rightContent.title}
                            </span>
                          </h4>
                          <p
                            style={{
                              color: "#617F7F",
                              fontWeight: "00",
                            }}
                          >
                            {rightContent.description}
                          </p>
                          <Link
                            className="hover-underline-animation-dark green-grad"
                            to={rightContent.link}
                          >
                            <h5 className="uk-margin-remove">
                              {rightContent.name}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Tiles />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default SwitcherButtonsContent
